@import url(https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

}

body {
    background: var(--color-bg);
    max-width: 100vw;
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    font-family: var(--font-family);
    background: linear-gradient(90deg, rgba(132, 9, 148, 1) 0%, rgba(0, 0, 0, 1) 72%);
}


.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 2rem 3rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}
.App{
    overflow: hidden;
}



.cardContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    margin-bottom: 1rem;
    transition: .2s all;

}

.cardContainer:hover {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.24) 10px 15px 8px;

}

.up {
    position: relative;
    flex: 1 0;
    width: 100%;
    z-index: 1;
}

.cardImage {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
}

.bottom {
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    position: relative;
    z-index: 5;
    margin-top: -2rem;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    border-top: 1px solid rgb(3, 37, 73);
    color: white !important;
}

.bottom_image-container {
    margin-top: -1.5rem;
}

.bottom_image {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 1px solid rgb(3, 37, 73);
}

.bottom_header-container {
    margin-top: .4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1rem;
}

.bottom_header-main-container {
    margin-bottom: 0 !important;
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottom_header-main {
    font-size: 20px;
}

.bottom_header-sub-container {
    display: flex;
    justify-content: center;
    font-size: .8rem;
}

.bottom_header-by {
    margin-right: .2rem;
    color: rgb(138, 147, 155);
    font-size: 18px;
}

.bottom_header-link {
    color: #40c6ee;
    font-size: 18px;
}

.bottom_text-container {
    position: absolute;
    left: 0;

}

.bottom-text_container-sub {
    display: flex;
    justify-content: center;
    width: 90%;
}

.bottom_button-container {
    display: flex;
    justify-content: flex-end;
}

.neonButton {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 15px 0 0;
    color: #03e9f4;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    letter-spacing: 4px;

}

.neonButton:hover {
    cursor: pointer;
    color: #03e9f4 !important;
}

.neonButton span {
    position: absolute;
    display: block;
}

.neonButton:hover span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
}

@-webkit-keyframes animate1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

@keyframes animate1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

a:hover span:nth-child(2) {
    top: -100px;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
}

@-webkit-keyframes animate2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

@keyframes animate2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

a:hover span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}

@-webkit-keyframes animate3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

@keyframes animate3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

a:hover span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
}

@-webkit-keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}

.modal-content {
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
}


.modal-footer {
    border: none;
    background-color: transparent;

}


.flipCardButton {
    background: var(--gradient-bar);
    border: none;
}

.closeText {
    font-size: 1.1rem;
    margin-bottom: 0 !important;
    margin-right: 1rem;
    font-family: var(--font-family);
}

.cardBadge {
    margin-left: 15px;
    height: 60px;
    width: auto;
}

@media (max-width: 1198px) {
    .cardBadge {
        height: 60px;
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    .cardBadge {
        height: 60px;
        margin-left: 0;

    }
}

@media (max-width: 574px) {
    .cardBadge {
        height: 60px;
        margin-left: 15px;

    }
}

@media (max-width: 300px) {
    .cardBadge {
        height: 60px;
        margin-left: 15px;

    }
}

.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}
.featureTitle h2 {
    font-size: 1.4rem!important;
}
@media (max-width: 574px) {
    .featureTitle h2 {
        font-size: 15px!important;
    }
}

.featureContainer{
    align-items: baseline!important;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }

    .featureTitle h2 {
        color: white;
        font-size: 1.2rem;
        margin-left: 1rem !important;
    }

    .featureContent {
        margin-bottom: 0 !important;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem !important;
        text-align: end !important;
    }

    .featureList {
        margin-right: 1rem;
        margin-bottom: 0 !important;
        max-width: 50%;
        margin-left: 2rem;
        font-size: 1rem !important;
    }
}

.featureMain {
    width: 100% !important;
    margin-bottom: .5rem;

}

.featureContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.featureTitle h2 {
    font-weight: bold;
    color: white;
    font-size: 1.6rem;
    margin-left: 1rem;
}

.featureContent {
    color: white;
    text-align: end;
    margin-bottom: 0 !important;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.4rem;
    transition: .2s all;
}


.featureList {
    color: white;
    text-align: end;
    margin-right: 1rem;
    margin-bottom: 0 !important;
    max-width: 50%;
    margin-left: 2rem;
    font-size: 1.5rem;
}

.featureLink {
    transition: all .2s;
}

.featureLink:hover {
    text-shadow: 0 0 10px #08F7FE, 0 0 15px #08F7FE, 0 0 20px #08F7FE, 0 0 25px #08F7FE, 0 0 30px #08F7FE, 0 0 35px #08F7FE;
}

.modal-content {
    background-color: transparent;
    border: none;
}

.gpt3__whythis {
    font-family: var(--font-family);

    min-height: 730px;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    padding: 2rem;
    background-color: transparent;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: all .5s;
}

/*.gpt3__whythis:hover {*/
/*    transform: rotateY(180deg);*/
/*}*/

.gpt3__whythis-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whythis-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whythis-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__features-container__feature-title h1 {
    font-size: 30px !important;
}

.gpt3__features-container_feature-text p {
    font-size: 25px !important;
}

.gpt3__whythis-heading {
    text-shadow: 0 0 10px #fff, 0 0 15px #ED50F1, 0 0 20px #ED50F1, 0 0 25px #ED50F1, 0 0 30px #ED50F1, 0 0 35px #ED50F1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.gpt3__whythis-heading h1 {
    text-align: center;
    font-size: 2.5rem;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    color: white;
    min-height: 100px;
}

.gpt3__whythis-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: white;
    cursor: pointer;
    transition: all ease 0.6s;
}

.gpt3__whythis-heading p:hover {
    font-size: 35px;
    transition: all ease 0.6s;
    color: #44CCEB;
}

.gpt3__whythis-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
}

.gpt3__whythis-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
    font-size: 25px;
}

/* Customizing feature component as per needs */
.gpt3__whythis-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whythis-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whythis-heading p {
        margin-top: 1rem;
    }

    .featureImage {
        height: auto;
        width: 256px !important;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whythis-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whythis-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whythis-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }


}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whythis-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }

    .featureImage {
        height: auto;
        width: 128px !important;
    }
}

.imagerocknverse {
    background-size: 100% 100%;
    opacity: 0.5;
    transition: ease 0.8s;
}

.imagerocknverse:hover {
    opacity: 1.0;
    transition: ease 0.8s;
}

.gpt3__container {
    display: flex;
}

.videoContainer {
    flex-grow: 1;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.featureImage {
    height: auto;
    width: 512px;
}

.whyThisMainContainer {
    display: flex;
    justify-content: center;
}

.visit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: .5s;
    padding: 2rem;
    min-height: 730px;
    background-color: transparent;
    height: 100%;
}

.visit-content {
    display: flex;
    flex-direction: column;

    text-align: justify;
    color: #fff;
}

.visit-content p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.visit-content h3 {
    font-family: var(--font-family);
    font-size: 25px;
    line-height: 45px;
    font-weight: 800;
}

.visit-btn {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.visit_img img {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: 256px;
    width: auto;
}

.visit-link {
    border-radius: 5px;
    color: white;
    border: 1px solid #fff;

    letter-spacing: 8px;
    cursor: pointer;
    font-weight: bold;
    -webkit-filter: drop-shadow(0 0 15px #C7636D) drop-shadow(0 0 50px #FEC457) contrast(2) brightness(2);
            filter: drop-shadow(0 0 15px #C7636D) drop-shadow(0 0 50px #FEC457) contrast(2) brightness(2);
    transition: .5s all;
    padding: 1rem;
    font-size: 20px;
    font-family: var(--font-family);
}

.visit-link:hover {
    color: white;
    -webkit-filter: drop-shadow(0 0 20px #C7636D) contrast(2) brightness(2);
            filter: drop-shadow(0 0 20px #C7636D) contrast(2) brightness(2);
    transition: 1s all;
}

.visit-btn button {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .visit {
        flex-direction: column;
    }

    .visit-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .visit {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .visit-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .visit-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.ctDescription{
    color: white;
    margin: 50px 15px 100px 15px ;
}

.exploreHeading {
    text-align: center;
    font-family: 'Akaya Telivigala', cursive !important;
    font-size: 40px;
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ED50F1, 0 0 20px #ED50F1, 0 0 25px #ED50F1, 0 0 30px #ED50F1, 0 0 35px #ED50F1;
}
@media (max-width: 574px) {
    .exploreHeading {
        font-size: 35px;
    }
}
.cardContainer{
    margin-bottom: 50px;
}
.filterDefault {
    font-size: 24px;
    color: #fff;
    transition: .5s;
    cursor: pointer;
}

.filterDefault:hover {
    -webkit-transform: scale(1.1) translateY(5px);
            transform: scale(1.1) translateY(5px);
    transition: .3s all;
    text-shadow: 0 0 5px #B124E0, 0 0 10px #B124E0, 0 0 20px #B124E0, 0 0 30px #B124E0;
}

.filterGlowSecondary {
    color: white !important;
    cursor: pointer;
    transition: .3s all;
    text-shadow: 0 0 5px #B124E0, 0 0 10px #B124E0, 0 0 20px #B124E0, 0 0 30px #B124E0, 0 0 40px #B124E0, 0 0 50px #B124E0, 0 0 60px #B124E0, 0 0 70px #B124E0, 0 0 80px #B124E0;
}

.filterArea {
    transition: .2s all;
}

.filterAreaVisible {
    display: none;
}

.filterAreaHidden {
    display: block;
}
.cardsContainer{
    margin: 0 3rem 0;
}
@media (max-width: 1200px) {
    .cardsContainer{
        margin: 0 0rem 0;
    }
}

.homePage-background {
    font-family: var(--font-family);

    font-size: 1.2rem;
    min-height: 100vh;
}

.header_logo_main {
    margin-top: 2rem;
    width: 256px;
    height: auto;
}

.homePage-legend {
    display: none;
}

@media (max-width: 600px) {
    .homePage-legend {
        display: block;
    }
}

.legendItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.legendImg img {
    height: 75px;
    width: auto;
}

.legendText {

    color: white;
    margin-left: 1rem;
}

:root {
  --font-family: 'Roboto Condensed', sans-serif;



  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #C7636D -13.86%, #FEC457 99.55%);

  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}


