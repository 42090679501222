.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}
.featureTitle h2 {
    font-size: 1.4rem!important;
}
@media (max-width: 574px) {
    .featureTitle h2 {
        font-size: 15px!important;
    }
}

.featureContainer{
    align-items: baseline!important;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }

    .featureTitle h2 {
        color: white;
        font-size: 1.2rem;
        margin-left: 1rem !important;
    }

    .featureContent {
        margin-bottom: 0 !important;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem !important;
        text-align: end !important;
    }

    .featureList {
        margin-right: 1rem;
        margin-bottom: 0 !important;
        max-width: 50%;
        margin-left: 2rem;
        font-size: 1rem !important;
    }
}

.featureMain {
    width: 100% !important;
    margin-bottom: .5rem;

}

.featureContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.featureTitle h2 {
    font-weight: bold;
    color: white;
    font-size: 1.6rem;
    margin-left: 1rem;
}

.featureContent {
    color: white;
    text-align: end;
    margin-bottom: 0 !important;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.4rem;
    transition: .2s all;
}


.featureList {
    color: white;
    text-align: end;
    margin-right: 1rem;
    margin-bottom: 0 !important;
    max-width: 50%;
    margin-left: 2rem;
    font-size: 1.5rem;
}
