.ctDescription{
    color: white;
    margin: 50px 15px 100px 15px ;
}

.exploreHeading {
    text-align: center;
    font-family: 'Akaya Telivigala', cursive !important;
    font-size: 40px;
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ED50F1, 0 0 20px #ED50F1, 0 0 25px #ED50F1, 0 0 30px #ED50F1, 0 0 35px #ED50F1;
}
@media (max-width: 574px) {
    .exploreHeading {
        font-size: 35px;
    }
}
.cardContainer{
    margin-bottom: 50px;
}
.filterDefault {
    font-size: 24px;
    color: #fff;
    transition: .5s;
    cursor: pointer;
}

.filterDefault:hover {
    transform: scale(1.1) translateY(5px);
    transition: .3s all;
    text-shadow: 0 0 5px #B124E0, 0 0 10px #B124E0, 0 0 20px #B124E0, 0 0 30px #B124E0;
}

.filterGlowSecondary {
    color: white !important;
    cursor: pointer;
    transition: .3s all;
    text-shadow: 0 0 5px #B124E0, 0 0 10px #B124E0, 0 0 20px #B124E0, 0 0 30px #B124E0, 0 0 40px #B124E0, 0 0 50px #B124E0, 0 0 60px #B124E0, 0 0 70px #B124E0, 0 0 80px #B124E0;
}

.filterArea {
    transition: .2s all;
}

.filterAreaVisible {
    display: none;
}

.filterAreaHidden {
    display: block;
}
.cardsContainer{
    margin: 0 3rem 0;
}
@media (max-width: 1200px) {
    .cardsContainer{
        margin: 0 0rem 0;
    }
}
