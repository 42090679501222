.visit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: .5s;
    padding: 2rem;
    min-height: 730px;
    background-color: transparent;
    height: 100%;
}

.visit-content {
    display: flex;
    flex-direction: column;

    text-align: justify;
    color: #fff;
}

.visit-content p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.visit-content h3 {
    font-family: var(--font-family);
    font-size: 25px;
    line-height: 45px;
    font-weight: 800;
}

.visit-btn {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.visit_img img {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: 256px;
    width: auto;
}

.visit-link {
    border-radius: 5px;
    color: white;
    border: 1px solid #fff;

    letter-spacing: 8px;
    cursor: pointer;
    font-weight: bold;
    filter: drop-shadow(0 0 15px #C7636D) drop-shadow(0 0 50px #FEC457) contrast(2) brightness(2);
    transition: .5s all;
    padding: 1rem;
    font-size: 20px;
    font-family: var(--font-family);
}

.visit-link:hover {
    color: white;
    filter: drop-shadow(0 0 20px #C7636D) contrast(2) brightness(2);
    transition: 1s all;
}

.visit-btn button {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .visit {
        flex-direction: column;
    }

    .visit-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .visit {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .visit-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .visit-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
