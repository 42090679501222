.homePage-background {
    font-family: var(--font-family);

    font-size: 1.2rem;
    min-height: 100vh;
}

.header_logo_main {
    margin-top: 2rem;
    width: 256px;
    height: auto;
}

.homePage-legend {
    display: none;
}

@media (max-width: 600px) {
    .homePage-legend {
        display: block;
    }
}
