.legendItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.legendImg img {
    height: 75px;
    width: auto;
}

.legendText {

    color: white;
    margin-left: 1rem;
}
