

.cardContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    margin-bottom: 1rem;
    transition: .2s all;

}

.cardContainer:hover {
    backface-visibility: hidden;
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.24) 10px 15px 8px;

}

.up {
    position: relative;
    flex: 1 0 0;
    width: 100%;
    z-index: 1;
}

.cardImage {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
}

.bottom {
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    position: relative;
    z-index: 5;
    margin-top: -2rem;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    border-top: 1px solid rgb(3, 37, 73);
    color: white !important;
}

.bottom_image-container {
    margin-top: -1.5rem;
}

.bottom_image {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 1px solid rgb(3, 37, 73);
}

.bottom_header-container {
    margin-top: .4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1rem;
}

.bottom_header-main-container {
    margin-bottom: 0 !important;
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottom_header-main {
    font-size: 20px;
}

.bottom_header-sub-container {
    display: flex;
    justify-content: center;
    font-size: .8rem;
}

.bottom_header-by {
    margin-right: .2rem;
    color: rgb(138, 147, 155);
    font-size: 18px;
}

.bottom_header-link {
    color: #40c6ee;
    font-size: 18px;
}

.bottom_text-container {
    position: absolute;
    left: 0;

}

.bottom-text_container-sub {
    display: flex;
    justify-content: center;
    width: 90%;
}

.bottom_button-container {
    display: flex;
    justify-content: flex-end;
}

.neonButton {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 15px 0 0;
    color: #03e9f4;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    letter-spacing: 4px;

}

.neonButton:hover {
    cursor: pointer;
    color: #03e9f4 !important;
}

.neonButton span {
    position: absolute;
    display: block;
}

.neonButton:hover span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: animate1 1s linear infinite;
}

@keyframes animate1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

a:hover span:nth-child(2) {
    top: -100px;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}

@keyframes animate2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

a:hover span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
}

@keyframes animate3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

a:hover span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}

.modal-content {
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
}


.modal-footer {
    border: none;
    background-color: transparent;

}


.flipCardButton {
    background: var(--gradient-bar);
    border: none;
}

.closeText {
    font-size: 1.1rem;
    margin-bottom: 0 !important;
    margin-right: 1rem;
    font-family: var(--font-family);
}

.cardBadge {
    margin-left: 15px;
    height: 60px;
    width: auto;
}

@media (max-width: 1198px) {
    .cardBadge {
        height: 60px;
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    .cardBadge {
        height: 60px;
        margin-left: 0;

    }
}

@media (max-width: 574px) {
    .cardBadge {
        height: 60px;
        margin-left: 15px;

    }
}

@media (max-width: 300px) {
    .cardBadge {
        height: 60px;
        margin-left: 15px;

    }
}
