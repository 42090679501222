.modal-content {
    background-color: transparent;
    border: none;
}

.gpt3__whythis {
    font-family: var(--font-family);

    min-height: 730px;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    padding: 2rem;
    background-color: transparent;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: all .5s;
}

/*.gpt3__whythis:hover {*/
/*    transform: rotateY(180deg);*/
/*}*/

.gpt3__whythis-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whythis-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whythis-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__features-container__feature-title h1 {
    font-size: 30px !important;
}

.gpt3__features-container_feature-text p {
    font-size: 25px !important;
}

.gpt3__whythis-heading {
    text-shadow: 0 0 10px #fff, 0 0 15px #ED50F1, 0 0 20px #ED50F1, 0 0 25px #ED50F1, 0 0 30px #ED50F1, 0 0 35px #ED50F1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.gpt3__whythis-heading h1 {
    text-align: center;
    font-size: 2.5rem;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    color: white;
    min-height: 100px;
}

.gpt3__whythis-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: white;
    cursor: pointer;
    transition: all ease 0.6s;
}

.gpt3__whythis-heading p:hover {
    font-size: 35px;
    transition: all ease 0.6s;
    color: #44CCEB;
}

.gpt3__whythis-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
}

.gpt3__whythis-container .gpt3__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
    font-size: 25px;
}

/* Customizing feature component as per needs */
.gpt3__whythis-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whythis-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whythis-heading p {
        margin-top: 1rem;
    }

    .featureImage {
        height: auto;
        width: 256px !important;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whythis-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whythis-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whythis-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }


}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whythis-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }

    .featureImage {
        height: auto;
        width: 128px !important;
    }
}

.imagerocknverse {
    background-size: 100% 100%;
    opacity: 0.5;
    transition: ease 0.8s;
}

.imagerocknverse:hover {
    opacity: 1.0;
    transition: ease 0.8s;
}

.gpt3__container {
    display: flex;
}

.videoContainer {
    flex-grow: 1;
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.featureImage {
    height: auto;
    width: 512px;
}

.whyThisMainContainer {
    display: flex;
    justify-content: center;
}
